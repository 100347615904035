import React from 'react'
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { Container, Row, Col } from 'react-bootstrap'
import Map from "../components/gMap"
import SEO from "../components/seo"
import { FaEnvelope, FaPhone} from 'react-icons/fa'
import ReactMarkdown from 'react-markdown'

const Page = ({ data }) => {

    const { address, access, schedule, phone, email } = data.mdx.frontmatter

    return (
        <Layout>
            <SEO title="Page de contact" />
            <Container className="bg-green-light rounded my-md-4 py-4 text-justify">
                <Row className="align-items-center">
                    <Col sm={8} md={6} className="mx-auto">
                        <Map></Map>
                    </Col>
                    <Col>
                        <div className="py-2">
                            <h3>Adresse</h3>
                            <ReactMarkdown parserOptions={{ commonmark: true }} source={address} />
                        </div>
                        <div className="py-2">
                            <h3>Accès</h3> 
                            <ReactMarkdown parserOptions={{ commonmark: true }} source={access} />
                        </div>
                        <div className="py-2">
                            <h3>Horaires d'ouverture</h3> 
                            <ReactMarkdown parserOptions={{ commonmark: true }} source={schedule} />
                        </div>
                        <div className="py-2">
                        <h3>Contact</h3>
                        <h5><strong className="mr-1"><FaEnvelope className="prefix"/> Email : </strong><a href={"mailto:" + email}>{email}</a></h5>
                        <h5><strong className="mr-1"><FaPhone className="prefix"/> Téléphone : </strong>{phone}</h5>
                        </div>

                    </Col>
                </Row>
            </Container>
        </Layout>
    )

}

export default Page

export const pageQuery = graphql`
    query($id: String) {
        mdx(id: { eq: $id }) {
            frontmatter {
                address
                access
                schedule
                email
                phone
            }
        }
    }`
