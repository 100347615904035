import React from 'react'

export default () => (
    <div className="map-responsive">
        <iframe title="ledav_place" width="600" height="450" frameBorder="0"
        src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ9Tao7Ipt5kcRR5YwPFQVR1E&key=AIzaSyAbDAbk5dPECaPviLbLqYyfkAD2uqaukZo"
        allowFullScreen 
        >
        </iframe>
    </div>
)